import api from "./iskibris"
import impersonatorApi from "./impersonator_client"

export function fetchCompanyJobs(companyId: number) {
  return api.get(`/api/hiring/v2/companies/${companyId}/jobs`)
}
export function fetchCompanyArchivedJobs(companyId: number) {
  return api.get(`/api/hiring/v2/companies/${companyId}/jobs/archived`)
}

export function fetchJob(jobId: number) {
  return api.get(`/api/hiring/v2/jobs/${jobId}`)
}

export function fetchJobSlots() {
  return api.get(`/api/hiring/v2/job-slots`)
}

export function fetchInventory(companyId: any) {
  return api.get(`/api/hiring/v2/companies/${companyId}/inventory`)
}

export function deleteJob(jobId: number) {
  return api.delete(`/api/hiring/v2/jobs/${jobId}`)
}

export function publishJob(jobId: number) {
  return api.post(`/api/hiring/v2/jobs/${jobId}/publish`)
}
export function unpublishJob(jobId: number, reason?: any) {
  let data = {}
  if(reason && reason !== ""){
    data = {...data, reason}
  }
  return api.post(`/api/hiring/v2/jobs/${jobId}/unpublish`, data)
}

export function submitSurvey(newObject: any) {
  return api.post("/api/user/survey", newObject)
}

export function getNotificationStats() {
  return api.get("/api/user/notifications/stats?employer=1")
}

export function getNotifications(onlyUnread?: number) {
  return api.get(`/api/user/notifications?employer=1&${onlyUnread === 1 ? "unread=1" : ""}`)
}

export function markNotificationAsRead(id: any) {
  return api.post(`/api/user/notifications/${id}/mark-as-read`)
}

export function markNotificationAsUnRead(id: any) {
  return api.post(`/api/user/notifications/${id}/mark-as-unread`)
}

export function deleteNotification(id: any) {
  return api.delete(`/api/user/notifications/${id}`)
}

export function markAllNotificationsAsRead() {
  return api.post(`/api/user/notifications/mark-all-as-read`)
}

export function endImpersonation() {
  return api.post(`/api/admin/impersonate/revoke`)
}

export function getImpersonationAuthToken(id: any) {
  return impersonatorApi.post(`/api/admin/impersonate/${id}`)
}

export function getImpersonatorProfile() {
  return impersonatorApi.get(`/api/user/profile`, {params: {"use_impersonator_auth": 1}})
}

export function getNotificationSettings() {
  return api.get(`/api/user/user-notification-settings`)
}

export function updateNotificationSettings(data: any) {
  return api.post(`/api/user/user-notification-settings`, data)
}

export function getCandidateNotes(id: number, page?: number | null, orderBy: string | null = "created_at", orderDirection: string | null = "desc") {
  return api.get(`/api/hiring/v2/users/${id}/comments?page=${page ?? 1}&order_by=${orderBy}&order_direction=${orderDirection}`)
}

export function createManualApplication(data: any) {
  return api.post(`/api/hiring/v2/applicants/manual`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json"
    }
  })
}

export function createFolder(id: number, data: any) {
  return api.post(`/api/hiring/v2/companies/${id}/folders`, data)
}

export function getCompanyFolders(id: number) {
  return api.get(`/api/hiring/v2/companies/${id}/folders`)
}

export function updateCompanyFolders(folderId: number, data: any) {
  return api.put(`/api/hiring/v2/companies/folders/${folderId}`, data)
}

export function deleteCompanyFolders(folderId: number) {
  return api.delete(`/api/hiring/v2/companies/folders/${folderId}`)
}

export function addApplicantsToFolder(id: number, data: any) {
  return api.post(`/api/hiring/v2/applicants/${id}/folders`, data)
}

export function removeApplicantsFromFolder(folderId: number, applicantId: any) {
  return api.delete(`/api/hiring/v2/applicants/${applicantId}/folders/${folderId}`)
}

export function addAtsSubscriptionToCompany(companyId: number) {
  return api.post(`/api/hiring/v2/companies/${companyId}/ats-subscription`)
}

export function getPlaces(keyword?: string | null, page = 1, perPage = 24) {
  return api.get(`/api/places/search?keyword=${keyword}&page=${page ?? 1}&per_page=${perPage ?? 12}`)
}

export function filterPlacesByIds(ids?: string | null, page = 1, perPage = 24) {
  return api.get(`/api/places/search?ids=${ids}&page=${page ?? 1}&per_page=${perPage ?? 12}`)
}