import React from "react"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import MailIcon from "@material-ui/icons/MailOutline"
// import PersonOutlineIcon from "@material-ui/icons/PersonOutline"

import Pusher from "pusher-js"
import Cookies from "js-cookie"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IApplicant } from "../../../types/applicants"
import { IActionType } from "../../../types"
import { applicantsActions } from "../../../store/actions/applicants"

import useStyles from "./styles"
import { useTranslation } from "react-i18next"
import ApplicantRating from "./ApplicantRating"
// import ApplicantStage from "./ApplicantStage"
import TabPanel from "../ApplicantTabPanel"

import { messagesActions } from "../../../store/actions/messages"
import { IFieldOfStudy, IUser } from "../../../types/user"
import CandidateDetails from "./CandidateDetails"
import ApplicantRatingStatus from "../MobileComponents/ApplicantRatingStatus"
import { useMediaQuery, useTheme } from "@material-ui/core"
import MessageHistory from "./MessageHistory"
import Can from "../../../utils/AccessControl/Can"
// import AccessDeniedTooltip from "../../AccessDeniedToolTip"
import { ICompany } from "../../../types/company"
import useGtm from "../../../hooks/useGtm"
import AccessDeniedTooltip from "../../AccessDeniedToolTip"
import DownloadApplicant from "../DownloadApplicant"
import ApplicantStageEnum from "./ApplicantStageEnum"
import CandidateNotes from "./CandidateNotes"
import ManualApplicationIndidator from "./ManualApplicationIndidator"
import CandidateFolders from "./CandidateFolders"
import { useHistory } from "react-router"
import ApplicantActions from "./ApplicantActions"
import AddApplicantToFolder from "./CandidateFolders/AddApplicantToFolder"
import ability from "../../../utils/AccessControl/ability"
// import ScrollSpyTabs from "../../ScrollspyTabs"
// import { useHistory } from 'react-router';

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

interface Props {
  company: ICompany
  applicant: IApplicant
  currentAuthUser: IUser
  currentUserInformation: IUser
  fieldsOfStudy: IFieldOfStudy[]
  centeredActions?: boolean
  closeSendMessage: () => void
  addSingleComment: (info: any) => void
  fetchUserInformation: (id: any) => void
  openSendMessage: (recipients: any) => void
  updateApplicantRating: (id: any, value: any) => void
  updateApplicantStatus: (id: any, value: any) => void
  updateApplicantRatingRealtime: (id: any, info: any) => void
  updateApplicantStageRealtime: (id: any, info: any) => void
}

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

const ApplicantDetails: React.FC<Props> = props => {
  const { applicant, addSingleComment, updateApplicantRatingRealtime, updateApplicantStageRealtime } = props

  const { tagEvent } = useGtm()

  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [value, setValue] = React.useState(0)
  const [showUserInformation, setShowUserInformation] = React.useState(true)

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const authToken = Cookies.get("auth_token")

  React.useEffect(() => {
    tagEvent({ event: "view_applicant_page", data: { applicant_name: applicant?.name, job_id: applicant?.job_id, job_title: applicant?.job_title } })
  }, [applicant, tagEvent])

  React.useEffect(() => {
    let pusher: Pusher | null
    let channelStr: any

    if (applicant && authToken) {
      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      channelStr = `private-application.${applicant.id}`

      let aChannel = pusher.subscribe(channelStr)
      aChannel.bind("new-comment-submitted", function (data: any) {
        addSingleComment(data)
      })

      aChannel.bind("application-rating-updated", function (data: any) {
        updateApplicantRatingRealtime(applicant.id, data)
      })

      aChannel.bind("application-stage-updated", function (data: any) {
        updateApplicantStageRealtime(applicant.id, data)
      })
    }

    return () => {
      if (applicant && authToken && !!pusher && !!channelStr) {
        pusher.disconnect()
      }
    }
  }, [applicant, authToken, addSingleComment, updateApplicantRatingRealtime, updateApplicantStageRealtime])

  const handleSendMessage = () => {
    console.log("Send message clicked")
    tagEvent({
      event: "compose_message_click",
      data: {
        applicant_id: applicant?.id,
        applicant_name: applicant?.name,
        applicant_work_permit_id: applicant?.work_permit_id,
        applicant_work_permit: t(`work_permit_id_${applicant?.work_permit_id}`)
      }
    })
    props.openSendMessage([{ ...props.applicant }])
  }

  // const handleUpdateApplicantStatus = async (value: any) => {
  //   tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: props.applicant?.stage_id, applicant_new_status: value } })
  //   await props.updateApplicantStatus(props.applicant.id, value)
  // }

  const handleUpdateApplicantStatusEnum = async (value: any) => {
    tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: props.applicant?.stage_id_enum, applicant_new_status: value } })
    await props.updateApplicantStatus(props.applicant.id, value)
  }

  const handleUpdateApplicantRating = async (value: any) => {
    tagEvent({ event: "applicant_rating_changed", data: { applicant_old_rating: props.applicant?.rating_id, applicant_new_rating: value } })
    await props.updateApplicantRating(props.applicant.id, value)
  }

  const handleShowUserInformation = () => {
    setShowUserInformation(!showUserInformation)
  }

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const handleViewJob = () => {
    history.push(`/jobs/${props.applicant.job_id}/edit`)
  }

  const applicantOptions = [
    {
      'name': 'download_application',
      'title': t("download_application"),
      'onClick': () => {},
      'children': <DownloadApplicant user={props.currentUserInformation} applicant={props.applicant} key={Math.random()} ><div>{t("download_application")}</div></DownloadApplicant>
    },
    {
      'name': 'add_candidate_to_folder',
      'title': t("add_candidate_to_folder"),
      'onClick': () => {},
      'children': <AddApplicantToFolder applicant={props.applicant} key={Math.random()} ><div>{t("add_candidate_to_folder")}</div></AddApplicantToFolder>
    },
    {
      'name': 'view_user_details',
      'title': t(!showUserInformation ? "view_user_details" : "hide_user_details"),
      'canClose': true,
      'onClick': handleShowUserInformation
    },
    {
      'name': 'view_job',
      'title': t("view_job"),
      'canClose': true,
      'onClick': handleViewJob
    },
  ]

  return (
    <React.Fragment>
      <div className={classes.applicantDetailsTop}>
        <div className={classes.applicantChoices} style={{justifyContent: props.centeredActions ? "center" : "flex-start"}}>
          <Can I="send-message-to-applicants" a="all">
            <Button variant="outlined" className={classes.showMessagingButton} onClick={handleSendMessage}>
              <MailIcon style={{ marginRight: "10px" }} />
              {t("send_message")}
            </Button>
          </Can>
          <Can not I="send-message-to-applicants" a="all">
            <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_send-message-to-applicants")}>
              <Button disabled variant="outlined" className={classes.showMessagingButton}>
                <MailIcon style={{ marginRight: "10px" }} />
                {t("send_message")}
              </Button>
            </AccessDeniedTooltip>
          </Can>
          <ApplicantRating readOnly={false} rating={props.applicant.rating_id} handleUpdate={handleUpdateApplicantRating} />
          <ApplicantStageEnum readOnly={false} stageEnum={props.applicant.stage_id_enum} handleUpdate={handleUpdateApplicantStatusEnum} />
        </div>
      </div>
      <div className={classes.detailsRoot}>
        <div className={classes.header}>
          <div className={classes.headerInner}>
            <div className={classes.headerNameContainer}>
              <Typography variant="h4">{props.applicant?.name}</Typography>
              {props.currentUserInformation ? <ApplicantActions applicant={applicant} options={applicantOptions} /> : null}
              {/* {props.currentUserInformation && <DownloadApplicant user={props.currentUserInformation} applicant={props.applicant} key={Math.random()} />} */}
            </div>
            <div className={classes.headerEmailContainer}>
              <Typography variant="subtitle1">{props.applicant?.email}</Typography>
              {/* {props.currentUserInformation ? (
                <Button className={classes.viewResumeButton} onClick={handleShowUserInformation}>
                  <PersonOutlineIcon className={classes.userIcon} /> <Typography className={classes.showUserProfile}>{t("view_user_details")}</Typography>
                </Button>
              ) : null} */}
            </div>
          </div>
        </div>
        <div className={classes.overlappingContainer}>
          <Paper>
            <Tabs value={value} textColor="secondary" onChange={handleTabChange} aria-label="tabs for messaging and applicant details">
              <Tab label={t("candidate_details")} {...a11yProps(0)} />
              <Tab label={t("message_history")} {...a11yProps(1)} />
              <Tab label={t("candidate_notes")} {...a11yProps(2)} />
              {
                ability.can("use-ats-system", 'all')
                ?
                (
                  <Tab label={t("candidate_folders")} {...a11yProps(3)} value={3} />
                )
                :
                (
                  <Can not I="use-ats-system" a="all">
                    <AccessDeniedTooltip link={"/features/ats"} linkLabel={t("go_to_ats_system_landing_pages")} body={t("billing_cannot_use_ats_system")}>
                      <Tab label={t("candidate_folders")} disabled {...a11yProps(3)} />
                    </AccessDeniedTooltip>
                  </Can>
                )
              }
            </Tabs>
          </Paper>
          {props.applicant?.manually_created_at && props.applicant?.manually_created_by && <ManualApplicationIndidator />}
          <TabPanel value={value} index={0}>
            <CandidateDetails
              company={props.company}
              applicant={props.applicant}
              currentUser={props.currentAuthUser}
              fieldsOfStudy={props.fieldsOfStudy}
              showUserInformation={showUserInformation}
              fetchUserInformation={props.fetchUserInformation}
              currentUserInformation={props.currentUserInformation}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MessageHistory />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CandidateNotes />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CandidateFolders />
          </TabPanel>
          {/* <ScrollSpyTabs
            tabsInScroll={
              [
                {
                  text: t("candidate_details"),
                  component: <CandidateDetails
                    company={props.company}
                    applicant={props.applicant}
                    currentUser={props.currentAuthUser}
                    fieldsOfStudy={props.fieldsOfStudy}
                    showUserInformation={showUserInformation}
                    fetchUserInformation={props.fetchUserInformation}
                    currentUserInformation={props.currentUserInformation}
                  />
                },
                {
                  text: t("message_history"),
                  component: <MessageHistory />
                },
                {
                  text: t("candidate_notes"),
                  component: <CandidateNotes />
                },
                {
                  text: t("candidate_folders"),
                  component: <CandidateFolders />
                },
              ]
            }
          /> */}
        </div>
      </div>

      {matchesMobile ? <ApplicantRatingStatus /> : null}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    currentAuthUser: state.userReducer.profile,
    applicant: state.applicantsReducer.singleApplicant,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy,
    currentUserInformation: state.applicantsReducer.currentUserInformation,
    permissions: state.userReducer.permissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  closeSendMessage: () => dispatch(messagesActions.closeSendMessage()),
  addSingleComment: (info: any) => dispatch(applicantsActions.addSingleComment(info)),
  openSendMessage: (recipients: any[]) => dispatch(messagesActions.openSendMessage(recipients)),
  fetchUserInformation: async (id: any) => dispatch(await applicantsActions.fetchCurrentApplicantUserInformation(id)),
  updateApplicantStageRealtime: (id: any, info: any) => dispatch(applicantsActions.updateApplicantStageRealtime(id, info)),
  updateApplicantRating: async (id: any, value: any) => dispatch(await applicantsActions.updateApplicantRating(id, value)),
  updateApplicantStatus: async (id: any, value: any) => dispatch(await applicantsActions.updateApplicantStatus(id, value)),
  updateApplicantRatingRealtime: (id: any, info: any) => dispatch(applicantsActions.updateApplicantRatingRealtime(id, info))
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDetails)
